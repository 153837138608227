<template>

    <div class="container">
      <div class="row">
        <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2">

          <b-card class="mt-3" header="Estilo Data Manager" :class="{ disabled: isBusy }">

            <b-form @submit="onSubmit">
              
              <ul class="form-options">
                
                <li v-for="(option, index) in syncOptions" :key="option.type" :class="[{ enabled: syncOptions[index].isSelected, active: (syncOptions[index].status === 'syncing') }, 'status-'+syncOptions[index].status]">
                  
                  <label class="form-options__item" :for="'input-'+index">
                    <span class="form-options__item__label">
                      {{ option.label }}<br />
                      <em v-if="syncOptions[index].status === 'syncing'">Syncing...</em>
                      <em v-else-if="syncOptions[index].status === 'success'">Sync Successful</em>
                      <em v-else-if="syncOptions[index].status === 'fail'">Sync Failed</em>
                      <em v-else-if="syncOptions[index].status === 'updating'">Updating...</em>
                      <em v-else-if="option.count > 0">{{ option.dateLastSynced+', '+option.count+' records found' }}</em>
                      <em v-else>{{ option.dateLastSynced }}</em>
                    </span>
                    
                    <b-spinner v-if="syncOptions[index].status === 'syncing'" label="Spinning"></b-spinner>
                    <b-form-checkbox :id="'input-'+index" v-model="syncOptions[index].isSelected" switch size="lg" v-else></b-form-checkbox>

                  </label>

                </li>
                
                <li :class="[{ enabled: shouldDeploy, active: (isBusy && isDeploying) }, 'status-'+deploymentStatus]">
                  
                  <label class="form-options__item form-options__item--deploy" for="deploy">
                    <span class="form-options__item__label">
                      {{ (isBusy && isDeploying) ? 'Deploying to '+deployType+'...' : 'Deploy' }}
                      <br />
                      <em class="error" v-if="deploymentError && deploymentError.length">{{ deploymentError }}</em>
                      <em v-else-if="deploymentStatus === 'success'">{{ 'Deployment to '+deployType+' was successful' }}</em>
                      <em v-else>Website will be deployed using the latest synced data. If data has been changed recently, make sure you also select items above in order to make sure it is deployed with the latest content.</em>
                    </span>

                    <b-dropdown v-if="!isDeploying" :variant="(deployType === 'production') ? 'outline-danger' : 'outline-primary'" :text="deployType">
                      <b-dropdown-item v-on:click.prevent="didSelectDeployType('local')">Deploy to Local</b-dropdown-item>
                      <b-dropdown-item v-on:click.prevent="didSelectDeployType('staging')">Deploy to Staging</b-dropdown-item>
                      <b-dropdown-item v-on:click.prevent="didSelectDeployType('production')">Deploy to Production</b-dropdown-item>
                    </b-dropdown>

                    <b-spinner v-if="isBusy && isDeploying" label="Spinning"></b-spinner>
                    <b-form-checkbox id="deploy" v-model="shouldDeploy" switch size="lg" v-else></b-form-checkbox>
                  </label>

                </li>
                
              </ul>
        
              <div class="submit-wrapper">
                <b-button type="submit" block variant="primary" :disabled="isBusy || (!itemsToSync && !shouldDeploy)">
                  <strong>{{ buttonLabel }}</strong>
                  <span>{{ buttonSubLabel }}</span>
                </b-button>
              </div>
            </b-form>

          </b-card>
    
        </div>    
      </div>
    </div>

</template>

<script>
export default {
  name: 'EstilloDataManager',
  data() {
      return {
        isBusy: false,
        isDeploying: false,
        shouldDeploy: false,
        deployType: 'staging',
        deploymentError: null,
        deploymentStatus: '',
        dataSource: 'https://api.estilo.furniture',
        syncOptions: [
          {
            type: 'Variants',
            label: 'Variants',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Products',
            label: 'Products',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Brand',
            label: 'Brands',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Category',
            label: 'Categories',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Collections',
            label: 'Collections',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Designer',
            label: 'Designers',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Finishes',
            label: 'Finishes',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Materials',
            label: 'Materials',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'PrimaryMenu',
            label: 'Primary Menu',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Product Documents',
            label: 'Product Documents',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Brand Documents',
            label: 'Brand Documents',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Projects',
            label: 'Projects',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Sectors',
            label: 'Sectors',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Swatches',
            label: 'Swatches',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Team',
            label: 'Team Members',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'About',
            label: 'About Content',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Slider',
            label: 'Homepage Slider',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Contact Info',
            label: 'Contact Info',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Footer',
            label: 'Footer Content',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },{
            type: 'Blog',
            label: 'Blog Posts',
            dateLastSynced: '',
            count: -1,
            status: 'updating',
            isSelected: false,
            needsUpdate: true,
          },
        ],
      };
  },
  
  props: {
/*
    dataSource: {
      type: String,
      default: 'http://root.estilo.test',
      //default: '/sync.php',
    },
*/
  },
  
  computed: {
    itemsToSync: function() {
      var itemsToSync = 0;
      this.syncOptions.forEach(item => {
        if(item.isSelected) {
          itemsToSync++;
        }
      })
      return itemsToSync;
    },
    buttonLabel: function(){

      if(this.isBusy && this.isDeploying) {
        return 'Deploying...';
      }
      
      if(this.isBusy) {
        return 'Syncing...';
      }
      
      var itemsToSync = this.itemsToSync;
      
      if(!itemsToSync) {
        return (this.shouldDeploy) ? 'Deploy Website' : 'No options selected';
      }
      
      return (this.shouldDeploy) ? 'Sync data then Deploy' : 'Sync data';
      
/*
      var label = (itemsToSync === 1) ? 'Sync 1 item' : 'Sync '+itemsToSync+' items';
      
      if(this.shouldDeploy) {
        label += ' & Deploy';
      }
      
      return label;
*/
      
    },
    buttonSubLabel: function(){
      
      if(this.isBusy) {
        return 'This may take several minutes';
      }
      
      var itemsToSync = this.itemsToSync;
      
      if(!itemsToSync) {
        return (this.shouldDeploy) ? 'May take several minutes' : 'Please select at least 1 option';
      }
      
      return (this.itemsToSync === 1) ? '1 item to sync' : itemsToSync+' items to sync';
      
    }
  },
  
  watch: {

  },
  
  methods: {
    didSelectDeployType: function(type) {
      this.deployType = type;
    },
    nextOptionToUpdate: function() {
      for (let i = 0; i < this.syncOptions.length; i++) {
        if(this.syncOptions[i].needsUpdate) {
          return this.syncOptions[i];
        }
      }
      return null;
    },
    updateNextOption: function() {
      var self = this,
          optionToUpdate = self.nextOptionToUpdate();
      
      if(!optionToUpdate) {
        self.finishUpdate();
        return;
      }
      
      optionToUpdate.status = 'updating';
      
      self.$http.get(self.dataSource, {params: {'action':'date_last_synced', 'type': optionToUpdate.type} }).then(response => {
      
        optionToUpdate.needsUpdate = false;
        optionToUpdate.status = '';
        optionToUpdate.dateLastSynced = response.body.dateFetched;
        optionToUpdate.count = response.body.count;
        
        self.updateNextOption();
      
      }, response => {
        console.log('ERROR', response);
        optionToUpdate.needsUpdate = false;
      });
      
    },
    finishUpdate: function() {
      //console.log('FINISH UPDATE!');
    },
    
    
    nextOptionToSync: function() {
      for (let i = 0; i < this.syncOptions.length; i++) {
        if(this.syncOptions[i].isSelected) {
          return this.syncOptions[i];
        }
      }
      return null;
    },
    syncNextOption: function() {
      var self = this,
          optionToSync = self.nextOptionToSync();
      
      if(!optionToSync) {
        self.finishSync();
        return;
      }
      
      optionToSync.status = 'syncing';
      
      self.isBusy = true;
      self.$http.get(self.dataSource, {params: {'action':'sync', 'type': optionToSync.type} }).then(response => {
      
        optionToSync.isSelected = false;
        optionToSync.needsUpdate = true;
        optionToSync.status = 'success';

        console.log(response.body);
      
        self.syncNextOption();
      
      }, response => {
        console.log('ERROR', response);
        
        optionToSync.status = 'fail';
        optionToSync.isSelected = false;
        
        self.isBusy = false;
      });
      
    },
    finishSync: function() {
      var self = this
      self.isBusy = false;
      
      if(self.shouldDeploy) {
        self.deploy();
        
      } else {
        setTimeout(function() {
          self.updateNextOption();
        }, 2000);
      }
    },
    
    
    
    
    
    
    
    
    deploy: function() {
      var self = this;

      self.deploymentError = null;
      self.isBusy = true;
      self.isDeploying = true;
      self.deploymentStatus = 'syncing';
      self.$http.get(self.dataSource, {params: {'action':'deploy', 'type': self.deployType} }).then(response => {

        console.log('Deployment output...');
        console.log(response.body);
      
        if(!response.body.success) {
          console.log(response.body.message);
          self.deploymentError = response.body.message;
          self.deploymentStatus = 'fail';
        } else {
          self.shouldDeploy = false;
          self.deploymentStatus = 'success';
        }
        
        self.finishDeploy();
      
      }, response => {
        console.log('ERROR ON DEPLOY', response);
        self.deploymentError = 'An unknown error occured';
        self.deploymentStatus = 'fail';
        self.finishDeploy();
      });
      
    },
    finishDeploy: function() {
      var self = this;
      
      this.isBusy = false;
      this.isDeploying = false;
      
      setTimeout(function() {
        self.updateNextOption();
      }, 2000);

    },
    
    
    
    
    onSubmit: function(e) {
      e.preventDefault()
      this.syncNextOption();
    },
  },
  
  components: {

  },
  
  created() {
    
  },
  mounted() {
    
    if(window.location.hostname === 'sync.estilo.furniture') {
      this.dataSource = 'https://api.estilo.furniture';
    }
    
    if(window.location.hostname === 'helper.estilo.test') {
      this.dataSource = 'http://root.estilo.test';
    }
        
    this.updateNextOption();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  &.disabled {
    .card-body {
      pointer-events: none;
      //opacity: 0.5;
    }
  }
  
  .card-body {
    padding: 0;
  }
}

.form-options {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  
  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    transition: opacity 0.25s ease-in-out, background 0.25s ease-in-out, color 0.25s ease-in-out;
    
    &:nth-child(odd) {
      border-right: solid 1px rgba(0, 0, 0, 0.125);
    }
    
    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
      border-right: none;
    }
    
    &.enabled {
      border-color: #007bff;
      border: solid 1px #007bff;
      margin-top: -1px;
      margin-left: -1px;
      
      &:nth-child(odd) {
        .form-options__item {

        }
      }

      &:last-child,
      &:nth-child(even) {
        margin-right: -1px;
        
        .form-options__item {
          //border-right: solid 1px #007bff;
        }
      }
    }
    
    &.active {
      background: #007bff;
      color: #fff;
    }
    
    &.status-success {
      color: #28a745;
    }
    
    &.status-fail {
      color: #dc3545;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 15px;
    
    &--deploy {
      min-height: 120px;
    }
    
    &__label {
      text-align: left;
      flex: 1;
      
      em {
        opacity: 0.5;
        font-size: 14px;
        font-style: normal;
        display: block;
        line-height: 18px;
        margin-top: 5px;
      }
    }
    
    .dropdown {
      margin: 0 15px;
      
      .btn {
        text-transform: capitalize;
      }
    }
    
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.submit-wrapper {
  padding: 15px;
  
  .btn {
    strong {
      display: block;
    }
    
    span {
      display: block;
      margin-top: -5px;
      font-size: 14px;
    }
  }
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.15rem;
  margin-right: 18px;
}

.error {
  font-weight: bold;
  color: red;
}

</style>
